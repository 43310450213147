import { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { IDocument } from '%/entities/document';
import { IOffer, IOfferData } from '%/entities/offer';
import { IValidationErrors } from '%/validation/d';
import { offerFillValidate } from '%/validation/offer';
import { getViewAbsoluteUrl } from '~/core/views';
import { CompanyLogo } from '~/shared/components/company-logo';
import { Button } from '~/shared/components/controls/button';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { InputDnd } from '~/shared/components/controls/input-dnd';
import { Delimeter } from '~/shared/components/delimeter';
import { DocumentList } from '~/shared/components/documents/list';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';
import { useUser } from '~/shared/hooks/use-user';
import { IFile } from '~/typings/file';

import okIcon from '@css-theme/icons/ok.svg';

import styles from './upload.module.styl';

const DocUpload:React.FC = () => {
  const [documents, setDocuments] = useState([] as IDocument[]);
  const [companyLogo, setCompanyLogo] = useState<string|null>(null);
  const [sending, setSendingState] = useState(false);
  const [sended, setSendedState] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const location = useLocation();
  const params = useParams<{id: string}>();
  const { user } = useUser();
  const { documentsList } = useDictionary();
  const { getOfferDocuments, uploadOfferFiles } = useOfferActions();

  const [listOpened, setListOpened] = useState(true);
  const toggleListOpened = () => { setListOpened(!listOpened) };

  const resetFormState = useCallback(() => {
    setSendedState(false);
    setFilesCount(0);
    setListOpened(true);
  }, []);

  const onChangeFiles = useCallback((files?:IFile[]) => {
    setFilesCount(files?.length || 0);
    setListOpened(false);
  }, []);

  const onSubmit = useCallback(async (values:IOfferData) => {
    setSendingState(true);
    const resp = await uploadOfferFiles(params.id || '', values);
    setSendingState(false);
    if (resp.errorStatus) return;
    setSendedState(true);
  }, []);

  const validate = useCallback((values:IOfferData):IValidationErrors<IOfferData> => {
    return offerFillValidate(values, false);
  }, []);

  const getData = async () => {
    const offer:IOffer = await getOfferDocuments(params.id || '');
    offer.data?.documents && setDocuments((offer.data.documents as IDocument[]).map((doc:IDocument) => ({
      ...doc,
      ...documentsList.find(document => document.docType === doc.docType)
    })));
    offer.offerCompany?.logo && setCompanyLogo(offer.offerCompany.logo as string);
  };

  useEffect(() => {
    getData();
  }, []);

  return user?.role ?
    <Navigate to={location?.state?.from?.pathname || getViewAbsoluteUrl('profile')} replace/> :
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <CompanyLogo url={companyLogo || ''}/>
          <Delimeter size="s"/>
          <h6>Загрузи свои документы для оформления на работу</h6>
          <Delimeter size="xxs"/>
          { !!documents?.length &&
            <div className={styles.documents}>
              <input type="checkbox" id="documentList" onChange={toggleListOpened} checked={listOpened}/>
              <label className={styles.documentsHead} htmlFor="documentList">Список документов</label>
              <DocumentList documents={documents} clear className={styles.documentsList}/>
            </div>
          }
        </div>
      </div>
      <div className={styles.body}>
        { !sended &&
          <Form<IOfferData>
            onSubmit={onSubmit}
            validate={validate}
            showBtn={false}>
            { ({ errors, filesRef }:IFormRenderProps<IOfferData>) => (
              <>
                <InputDnd
                  name="files"
                  filesRef={filesRef}
                  errors={errors.files}
                  uploadUrl={`/api/files/${params.id}`}
                  onChange={onChangeFiles}
                  view="dnd-mobile"
                  buttonProps={{
                    text: 'Загрузить',
                    accent: true
                  }}
                />
                { !!filesCount &&
                  <>
                  <Delimeter size="s"/>
                  <Button accent loading={sending} type="submit">Отправить</Button>
                  </>
                }
              </>
            )}
          </Form>
        }
        { sended &&
          <div className={styles.complete}>
            <div/>
            <div>
              <img src={okIcon}/>
              <Delimeter size="s"/>
              Отправлено
            </div>
            <Button bordered onClick={resetFormState}>Загрузить новые файлы</Button>
          </div>
        }
      </div>
    </div>;
};

export default DocUpload;